
import {
  DownloadOutlined,
  PlusCircleOutlined,
  LinkOutlined,
  UploadOutlined,
} from "@ant-design/icons-vue";
import { defineComponent, reactive, ref, computed } from "vue";
import { useRouter } from "vue-router";
import { message } from "ant-design-vue";
import { CommentType } from "@/views/VehicleLicense/types";
import downloadFile from "@/utils/claim/downloadFile";
import base64toBlob from "@/utils/rv/base64toBlob";
import {
  getBasicInfo,
  updateCheckInfo,
  addComment,
  deleteComment,
  getCommentList,
} from "@/API/vl/vlSICCOChecking";
import NoticeModal from "@/components/NoticeModal.vue";
import ImagePreview from "@/views/RetailValidation/component/ImagePreview.vue";
import PdfPreview from "@/views/RetailValidation/component/PdfPreview.vue";

const chooseArray = ["Y", "N"];
const checkResultArray = [
  { text: "审核通过", value: "Approved" },
  { text: "拒绝", value: "Rejected" },
];
const columns = [
  {
    title: "Checking Note",
    dataIndex: "comment",
    slots: { customRender: "comment" },
  },
  { title: "Operation", width: 90, slots: { customRender: "operation" } },
];
export default defineComponent({
  components: {
    DownloadOutlined,
    PlusCircleOutlined,
    LinkOutlined,
    NoticeModal,
    ImagePreview,
    PdfPreview,
    UploadOutlined,
  },
  setup() {
    const activeKey = ref<string>("1");
    const vinNo = sessionStorage.getItem("samplingCheckingVinNo");
    const dealerCode = sessionStorage.getItem("samplingCheckingDealerCode");
    const router = useRouter();
    const basicParams = ref({});
    const otherParams = ref({});
    const picParams = ref({});
    const checkingParams = ref({});
    const appealParams = ref({});
    const commentParams = ref<CommentType[]>([]);
    const addDisabled = ref<boolean>(false);
    const addCommentInfo = ref("");
    const count = computed(() => commentParams.value.length + 1);
    const checkArray = ref({});
    const isInit = ref<number>(0);
    const downloadParams = ref("");
    const modalVisible = ref(false);
    const modalData = reactive({});
    const modalParams = reactive({
      noticeType: "",
      content: "",
      btnVisible: true,
    });
    const appealDownloadDetail = ref()

    const effectiveSubmittedChange = () => {
      // checkingParams.feedbackInfo
      if ((checkingParams as any).value.effectiveSubmitted == "Y") {
        checkArray.value = ["审核通过"];
      } else if ((checkingParams as any).value.effectiveSubmitted == "N") {
        checkArray.value = [
          "未通过零售激励适用性校验",
          "车牌号提报信息不准确",
          "行驶证未通过有效性校验",
          "未在上牌有效期内上牌",
          "未在提交有效期内提交行驶证",
          "车架号信息与所提供行驶证不一致",
          "车牌号信息与所提供行驶证不一致",
          "注册日期信息与所提供行驶证不一致",
        ];
      }
      if (isInit.value == 0) {
        (checkingParams as any).value.feedbackInfo = [checkArray.value[0]];
      } else {
        isInit.value = 0;
      }
    };
    const getData = () => {
      if (vinNo) {
        const params = {
          vinNo: vinNo,
          dealerCode: dealerCode,
        };
        getBasicInfo({ params }).then((res: any) => {
          basicParams.value = res.retailInfoRespVO;
          otherParams.value = res.tengShiRespVO;
          picParams.value = res.downloadDetail;
          checkingParams.value = res.checkInfo;
          (checkingParams.value as any).feedbackInfo =
            res.checkInfo.feedbackInfo === ""
              ? []
              : res.checkInfo.feedbackInfo.split(",");
          commentParams.value = res.commentList;
          appealParams.value = res.appealInfo;
          isInit.value = 1;
          appealDownloadDetail.value = res.appealDownloadDetail;
          effectiveSubmittedChange();
        });
      }
    };
    const init = () => {
      getData();
    };

    init();

    // 下载文件
    const downloadImg = (fileName: string, resource: string) => {
      const a = document.createElement("a");
      a.href = resource;
      a.setAttribute("download", fileName);
      a.click();
    };

    const handleDownloadImg = async () => {
      const res = await getBasicInfo({
        params: {
          vinNo: vinNo,
          dealerCode: dealerCode
        }
      })
      downloadImg(res.downloadDetail.docName, res.downloadDetail.downloadUrl)
    }

    const handleDownloadAppealImg = async () => {
      const res = await getBasicInfo({
        params: {
          vinNo: vinNo,
          dealerCode: dealerCode
        }
      })
      downloadImg(res.appealDownloadDetail.docName, res.appealDownloadDetail.downloadUrl)
    }

    const getComment = () => {
      const params = {
        vinNo: vinNo,
        dealerCode: dealerCode,
      };
      getCommentList({ params }).then((res: any) => {
        commentParams.value = res;
      });
    };

    const cancel = () => {
      router.push({ path: "/vehiclelicense/siccoChecking" });
    };

    const isFeedBackInfoEmpty = ref(false);
    const saveCheckInfo = () => {
      if ((checkingParams.value as any).feedbackInfo.length === 0) {
        isFeedBackInfoEmpty.value = true;
        return;
      }
      const arr: any = [];

      for (const key in checkingParams.value) {
        arr.push(checkingParams.value[key]);
      }
      if (arr.some((item: any) => !item)) {
        modalVisible.value = true;
        modalParams.noticeType = "warning";
        modalParams.content = "当前存在为空的核查点，是否确认继续?";
        modalParams.btnVisible = true;
        Object.assign(modalData, modalParams);
      } else {
        checkingParams.value["vinNo"] = vinNo;
        checkingParams.value["dealerCode"] = dealerCode;
        updateCheckInfo({
          ...checkingParams.value,
          feedbackInfo: (checkingParams.value as any).feedbackInfo.join(","),
        }).then((res: any) => {
          if (res === "") {
            message.success("更新数据成功");
            router.push({ path: "/vehiclelicense/siccoChecking" });
          }
        });
      }
    };

    const closeModal = (param: { isVisible: boolean; continue: boolean }) => {
      modalVisible.value = param.isVisible;
      if (param.continue) {
        checkingParams.value["vinNo"] = vinNo;
        checkingParams.value["dealerCode"] = dealerCode;
        updateCheckInfo({
          ...checkingParams.value,
          feedbackInfo: (checkingParams.value as any).feedbackInfo.join(","),
        }).then((res: any) => {
          if (res === "") {
            message.success("更新数据成功");
            router.push({ path: "/vehiclelicense/siccoChecking" });
          }
        });
      }
    };

    const handleAdd = () => {
      addCommentInfo.value = "";
      const id = `${count.value}`;
      const newData = {
        id: parseFloat(id),
        comment: "",
      };
      commentParams.value.push(newData);
      addDisabled.value = true;
    };

    const addCommentList = () => {
      if (addCommentInfo.value && addCommentInfo.value.trim()) {
        if (vinNo) {
          const params = {
            comment: addCommentInfo.value.trim(),
            vinNo: vinNo,
            dealerCode: dealerCode,
          };
          // 添加comment
          addComment(params).then((res: any) => {
            getComment();
            addDisabled.value = false;
          });
        }
      } else {
        addDisabled.value = true;
      }
    };

    const handleDelete = (record: any) => {
      const id = record.id;
      if (record.comment && record.comment.trim()) {
        deleteComment(id).then((res: any) => {
          if (res === "") {
            message.success("删除数据成功");
            getComment();
          }
        });
      } else {
        const data = ref<CommentType[]>([
          {
            id: 0,
            comment: "",
          },
        ]);
        commentParams.value.forEach((item: any) => {
          if (item.id !== id) {
            data.value.push(item);
          }
        });
        commentParams.value = [];
        Object.assign(commentParams, data);
      }
    };

    const exportFile = () => {
      const downparams = {
        url: `./rvapi/vl/handlefile/appeal/batchDownload?vinNo=${vinNo}&dealerCode=${dealerCode}`,
        method: "post",
        params: {},
        fileName: `${+new Date()}.zip`,
      };
      downloadFile(downparams, "application/zip; char-set=UTF-8");
    };

    const computeSize = (fileSize: string) => {
      const sizeNum = parseFloat(fileSize) / 1024 / 1024;
      const sizeKb = parseFloat(fileSize) / 1024;
      if (sizeNum < 1) {
        return sizeKb.toFixed(2) + "KB";
      } else {
        return sizeNum.toFixed(2) + "MB";
      }
    };

    const singleExportFile = (item: any) => {
      let URL = {};
      const fileName = item.fileName;
      const fileUrl = item.base64String;
      if (fileName.indexOf(".docx") > -1) {
        URL = base64toBlob(
          fileUrl,
          "application/vnd.openxmlformats-officedocument.wordprocessingml.document"
        );
      } else if (fileName.indexOf(".xlsx") > -1) {
        URL = base64toBlob(
          fileUrl,
          "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
        );
      } else if (fileName.indexOf(".jpg") > -1) {
        URL = base64toBlob(fileUrl, "image/jpeg");
      } else if (fileName.indexOf(".pdf") > -1) {
        URL = base64toBlob(fileUrl, "application/pdf");
      }
      const downloadElement = document.createElement("a");
      downloadElement.href = window.URL.createObjectURL(URL);
      downloadElement.download = fileName;
      downloadElement.click();
    };

    return {
      chooseArray,
      checkResultArray,
      checkArray,
      effectiveSubmittedChange,
      columns,
      activeKey,
      router,
      basicParams,
      otherParams,
      picParams,
      checkingParams,
      commentParams,
      getData,
      init,
      downloadImg,
      addCommentInfo,
      addCommentList,
      addDisabled,
      handleAdd,
      saveCheckInfo,
      getComment,
      handleDelete,
      cancel,
      isInit,
      appealParams,
      exportFile,
      computeSize,
      downloadParams,
      singleExportFile,
      modalVisible,
      modalData,
      closeModal,
      isFeedBackInfoEmpty,
      appealDownloadDetail,
      handleDownloadImg,
      handleDownloadAppealImg
    };
  },
});
